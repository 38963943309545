import { AddCorporationForm } from "../../components/forms/AddCorporationForm";
import AddUserToCorporationForm from "../../components/forms/AddUserToCorporationForm";
import AddUserToPropertyForm from "../../components/forms/AddUserToPropertyForm";
import SendWelcomeEmail from "../../components/forms/SendWelcomeEmail";
import MigrateTicketCategories from "../../components/forms/MigrateTicketCategories";
import RevertMoveOutForm from "../../components/forms/RevertMoveOutForm";
import GenerateTenancyReport from "../../components/forms/GenerateTenancyReport";
import ResetPayment from "../../components/forms/ResetPayment";
import BackupDatabase from "../../components/forms/BackupDatabase";
import UpdateCorporationPaymentFee from "../../components/forms/UpdateCorporationPaymentFee";

export default function DashboardContainer() {
    return (
        <>
            <AddUserToPropertyForm />
            <ResetPayment />
            <RevertMoveOutForm />
            <GenerateTenancyReport />
            <AddUserToCorporationForm />
            <AddCorporationForm />
            <SendWelcomeEmail />
            <MigrateTicketCategories />
            <BackupDatabase />
            <UpdateCorporationPaymentFee/>
            <h1>&nbsp;</h1>
        </>
    )
}